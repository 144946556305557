import { createApp } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'
import App from './App.vue'
import store from './store'
import "./index.css"

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () =>
			import('./views/Home.vue'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () =>
			import('./views/Login.vue'),
	},
	{
		path: "/:pathMatch(.*)",
		component: () =>
			import('./views/NotFound.vue'),
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

let app = createApp(App);
app.use(router);
app.use(store);

store.subscribe((mutation) => {
    if (mutation.type === "SET_USER_DATA") {
        router.push({ path: "/home" })
    } else {
		router.push({ path: "/login" })
	}
})

const userString = localStorage.getItem('user')
if (userString) {
    const userData = JSON.parse(userString)
    store.commit('SET_USER_DATA', userData)
}

app.mount("#app")