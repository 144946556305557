import { createStore } from 'vuex'
import { Magic } from 'magic-sdk'
import { OAuthExtension } from '@magic-ext/oauth'

const magic = new Magic(process.env.VUE_APP_MAGIC_KEY, {
	extensions: [new OAuthExtension()]
});

export default createStore({
	state: {
		user: null
	},
	mutations: {
		SET_USER_DATA(state, userData) {
			state.user = userData
			localStorage.setItem('user', JSON.stringify(userData))
		},
		CLEAR_USER_DATA() {
			localStorage.removeItem('user')
			location.reload()
		},
	},
	actions: {
		async login({ commit }, email) {
			await magic.auth.loginWithMagicLink(email)
			const data = await magic.user.getMetadata()
			commit('SET_USER_DATA', data)
		},
		async logout({ commit }) {
			await magic.user.logout()
			commit('CLEAR_USER_DATA')
		},
		async loginWith({ commit }, data) {
			console.log("logging in with " + data.service)
			await magic.oauth.loginWithRedirect({
				provider: data.service,
				redirectURI: `${window.location.origin}/`,
			})
		},
	}
})